// import { header } from "./modules/header";
// import { anchorScroll } from "./modules/anchorScroll";
// import { inview } from "./modules/inview";
import { accordion } from "./modules/accordion";
import { smoothScroll } from "./modules/smoothScroll";
import { viewportHeight } from "./modules/viewportHeight";
import { hamburgerMenu } from "./modules/hamburgerMenu";

window.addEventListener("DOMContentLoaded", () => {
  accordion();
  smoothScroll();
  viewportHeight();
  hamburgerMenu();
});
